ion-item {
  font-size: var(--ion-font-size);

  &.item-text {
    ion-input {
      pointer-events: none;

      input {
        pointer-events: none;
        user-select: none;
        -webkit-user-select: none;
      }
    }
  }
}

ion-list-header {
  --padding: 0;
  --ion-safe-area-left: 0;

  &:not(:first-child) {
    margin-top: var(--ion-margin);
  }

  & + ion-item {
    margin-top: 0 !important;
  }
}
