@mixin rings($duration, $delay) {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -8px;
  left: -8px;
  right: 0;
  bottom: 0;
  content: '';
  height: 100%;
  width: 100%;
  border: 8px solid rgba(var(--ion-color-primary-rgb), 0.618);
  border-radius: 100%;
  animation-name: ripple;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  z-index: -1;
}

.map-marker {
  $marker-color: #aa3300;

  display: block;
  position: relative;

  &.current {
    width: 16px;
    height: 16px;
    margin-top: -8px; // can't use transform here otherwise z-index will not work
    margin-left: -8px;
    border-radius: 100%;
    border: 2px solid white;
    background-color: var(--ion-color-primary);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);

    &::after {
      @include rings(3s, 0s);
    }

    &::before {
      @include rings(3s, 0.5s);
    }
  }

  &.start,
  &.end {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    border-bottom-right-radius: 0;
    background: $marker-color;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    transform-origin: bottom right;
    transform: translate(-100%, -100%) rotate(45deg);

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &::before {
      content: '';
      width: 18px;
      height: 18px;
      border-radius: 100%;
      background-color: white;
      transform: translate(-50%, -50%);
    }

    &::after {
      z-index: 1;
      color: $marker-color;
      font-weight: 600;
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &.start::after {
      content: 'A';
    }

    &.end::after {
      content: 'B';
    }
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }

  to {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1);
  }
}
