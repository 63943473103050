.item-button,
.item-input,
.item-select,
.item-checkbox {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;

  &:not(.item-button) {
    --ripple-color: transparent;
  }

  ion-icon[slot='start'] {
    margin-right: var(--ion-margin);
  }

  & + .MuiFormHelperText-root {
    font-size: 0.85rem !important;
    margin-top: calc(var(--ion-margin) / 2) !important;
  }

  .required {
    font-family: monospace;
    color: var(--ion-color-danger);
  }

  .help {
    margin: 0;
    --padding-end: calc(var(--ion-margin) / 2);
    --padding-start: calc(var(--ion-margin) / 2);

    &:first-child {
      margin-inline-start: calc(var(--ion-margin) / 2);
    }

    &.center {
      align-self: center;
    }

    &.end {
      align-self: flex-end;
    }

    ion-icon + ion-text {
      font-size: 0.85rem;
      margin-left: 0.5em;
    }
  }
}

.item-input,
.item-select {
  &:not(.item-has-focus):not(.item-has-placeholder):not(.item-has-value) {
    .label-floating {
      flex: 1 1 0%;
      transform: translate3d(0, 130%, 0);
    }
  }
}

.item-select ion-select,
.item-input .native-input {
  height: 35px;
}

.item-input,
.item-select {
  .readonly &,
  &.readonly {
    &.item-has-focus {
      --inner-border-width: 0;
      --highlight-color-focused: var(--ion-item-border-color);

      .label-stacked,
      .label-floating {
        color: var(--ion-color-dark);
      }
    }

    .select-disabled,
    .label-floating,
    .label-stacked {
      opacity: 1;
    }
  }
}

.item-input ion-input {
  display: block;
}

.select-popover {
  --width: calc(100vw - var(--ion-margin) * 4);

  .item-label {
    --inner-border-width: 0;

    ion-label {
      overflow: visible;
      white-space: normal;
      padding: calc(var(--ion-padding) * 3 / 4) 0;
    }

    ion-radio {
      margin-left: calc(var(--ion-margin) / 2);
    }
  }
}

.item-checkbox {
  ion-checkbox {
    margin: 2px var(--ion-margin) 0 0;
    align-self: flex-start;
  }

  ion-label {
    margin: 0;
    align-self: flex-start;
    white-space: normal !important;
  }
}

.input-group {
  .item-button,
  .item-input,
  .item-select,
  .item-checkbox {
    &:not(:first-child) {
      margin-top: var(--ion-margin);
    }
  }

  & + ion-button {
    margin-top: calc(var(--ion-margin) * 2);
  }
}

ion-select::part(icon) {
  transform: none !important;
}

.MuiFormControl-root {
  &,
  &:hover {
    .MuiInput-underline:before,
    .MuiInput-underline:after {
      transition: none;
    }

    .MuiInput-underline:not(.Mui-disabled):before {
      border-bottom: 1px solid var(--ion-border-color);
    }
  }

  .MuiFormLabel-root {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .MuiSelect-select:focus {
    background: transparent;
  }

  .Mui-required .MuiFormLabel-asterisk {
    font-family: 'Roboto-Numerals';
    color: var(--ion-color-danger);
  }
}

.dark {
  .MuiFormControl-root {
    &,
    &:hover {
      .MuiInput-underline:not(.Mui-disabled):before {
        border-bottom: 1px solid #333333;
      }
    }
  }
}
