@font-face {
  font-family: 'Roboto-Numerals';
  src: url('/assets/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  unicode-range: U+30-39;
}

@font-face {
  font-family: 'Raleway';
  src: url('/assets/fonts/Raleway-Regular.ttf') format('truetype');
  font-weight: 400;
}

* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input,
textarea {
  -webkit-touch-callout: default !important;
  user-select: text !important;
}

html {
  font-size: var(--ion-font-size);
  line-height: var(--ion-line-height);
  -webkit-font-variant-ligatures: none;
  -moz-font-variant-ligatures: none;
  font-variant-ligatures: none;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}
