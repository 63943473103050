ion-action-sheet {
  .action-sheet-container {
    .action-sheet-group:last-child {
      padding-bottom: calc(var(--ion-padding) / 2);
    }

    .action-sheet-title,
    .action-sheet-button {
      font-size: var(--ion-font-size);
    }

    .action-sheet-title {
      height: auto;
      padding-top: var(--ion-padding);
      padding-bottom: calc(var(--ion-padding) / 2);
      font-size: 0.8em;
      text-transform: uppercase;
    }

    .action-sheet-icon {
      font-size: 1.25em;
      padding-bottom: 0;
      margin-inline-end: var(--ion-margin);
    }

    .action-sheet-selected {
      --button-color-selected: var(--ion-color-primary);

      &.sc-ion-action-sheet-md {
        font-weight: 400;
      }
    }

    .action-sheet-cancel {
      --button-color: var(--ion-color-danger);
    }

    .action-sheet-button.danger {
      --button-color: var(--ion-color-danger);
      --button-color-focused: var(--button-color);
      --button-color-hover: var(--button-color);
      --button-color-selected: var(--button-color);
      --button-color-activated: var(--button-color);

      .action-sheet-icon {
        --color: var(--ion-color-danger);
      }
    }
  }
}
