ion-alert {
  .alert-wrapper {
    .alert-message {
      font-size: var(--ion-font-size);
    }

    .alert-input-group {
      padding-top: 0;
    }

    .alert-button {
      &.danger {
        color: var(--ion-color-danger);
      }
    }
  }
}
