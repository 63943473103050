ion-button {
  --box-shadow: none;
}

button.is-link {
  display: inline;
  margin: 0;
  padding: 0;
  background: transparent;
  color: var(--ion-color-primary);
  font-size: inherit;
  outline: none;
}
