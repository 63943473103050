ion-content {
  background-color: var(--ion-background-color);

  --padding-top: calc(var(--ion-padding) * 1);
  --padding-start: calc(var(--ion-padding) * 1.5 + var(--ion-safe-area-left, 0));
  --padding-end: calc(var(--ion-padding) * 1.5 + var(--ion-safe-area-right, 0));

  &.display {
    --padding-top: calc(var(--ion-padding) * 4 + var(--ion-safe-area-top, 0));
    --padding-start: calc(var(--ion-padding) * 2 + var(--ion-safe-area-left, 0));
    --padding-end: calc(var(--ion-padding) * 2 + var(--ion-safe-area-right, 0));
  }

  &:last-child {
    --padding-bottom: calc(var(--ion-padding) * 1);

    &.display {
      --padding-bottom: calc(var(--ion-padding) * 2 + var(--ion-safe-area-bottom, 0));
    }
  }

  &.fab {
    --padding-top: calc(var(--ion-padding) * 6.5);
  }

  &.toolbar {
    // fix wrong content offset top when material ui model opens
    --offset-top: 56px !important;
    --padding-top: calc(var(--ion-padding) * 6.5 - 56px);
  }

  &.fullscreen {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;

    ion-fab.fab-vertical-top {
      top: calc(var(--ion-margin) * 1.5 + var(--ion-safe-area-top, 0));
    }
  }
}
