ion-fab {
  &.flat {
    ion-fab-button {
      --box-shadow: none;
    }
  }

  &.fab-horizontal-start {
    left: calc(var(--ion-margin) * 1.5 + var(--ion-safe-area-left, 0));
  }

  &.fab-horizontal-end {
    right: calc(var(--ion-margin) * 1.5 + var(--ion-safe-area-right, 0));
  }

  &.fab-vertical-top {
    top: calc(var(--ion-margin) * 1.5);
  }

  &.fab-vertical-bottom {
    bottom: calc(var(--ion-margin) * 1.5 + var(--ion-safe-area-bottom, 0));
  }

  &.fab-group {
    display: flex;
    flex-direction: row;

    ion-fab-button:not(:first-child) {
      margin-left: var(--ion-margin);
    }
  }
}
