ion-card {
  box-shadow: none;
  border-radius: 8px;
  border: 0.75px solid var(--ion-border-color);
  margin: 0 0 var(--ion-margin) 0;

  ion-card-title {
    font-size: 1.25rem;
  }
}
